import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { getGlobalEditor, globalEditor } from '../../api/editor';
import { v4 as uuidv4 } from 'uuid';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';

const Global = () => {
    const [count, setCount] = useState(0);
    const [settingId, setSettingId] = useState("");
    const [fonts, setFonts] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [searchSubTerm, setSearchSubTerm] = useState<string>("");
    const [limitedFonts, setLimitedFonts] = useState<string[]>([]);
    const [limitedSubFonts, setLimitedSubFonts] = useState<string[]>([]);
    const [isActive, setIsActive] = useState(false);
    const [isDropActive, setIsDropActive] = useState(false);
    const [selectFont, setSelectFont] = useState("");
    const [selectSubFont, setSelectSubFont] = useState("");
    const [hexColor, setHexColor] = useState("#f17013");

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHexColor(event.target.value);
    };


    useEffect(() => {
        fetch(
            "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyASXRziJBwhiCz1cxNUeOB8lJuoCMSQkCw"
        )
            .then((response) => response.json())
            .then((data) => {
                const fontNames = data.items.map((item: { family: string }) => item.family);
                setFonts(fontNames);
                setLimitedFonts(fontNames.slice(0, 7000));
                setLimitedSubFonts(fontNames.slice(0, 10));

            });
        getGlobalEditor().then((data) => {
            setSettingId(data.data.id);
            setSelectFont(data.data.header_font);
            setSelectSubFont(data.data.body_font);
            setHexColor(data.data.theme_color);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        const filteredFonts = fonts.filter((font) =>
            font.toLowerCase().includes(value.toLowerCase())
        );
        setLimitedFonts(filteredFonts.slice(0, 10));
    };

    const handleSubSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchSubTerm(value);
        const filteredFonts = fonts.filter((font) =>
            font.toLowerCase().includes(value.toLowerCase())
        );
        setLimitedSubFonts(filteredFonts.slice(0, 10));
    };


    const globalUpdate = () => {
        let editorJson = {
            "settingId": settingId ? settingId : uuidv4(),
            "theme_color": hexColor,
            "header_font": selectFont,
            "body_font": selectSubFont
        }
        globalEditor(editorJson).then((data) => {
            showNotifications(TOAST_TYPE.success, data.message);
            setCount(count + 1)
        }).catch((err) => { console.log(err); });
    }



    return (
        <div>
            <div className='profileHeading' style={{ borderBottom: '1px solid #EAECF0' }}>
                <h6>Website Customization (Page Editor)</h6>
                <p>Enable, disable and customize the content of your landing page.</p>
            </div>

            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Branding</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p>Primary Color</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" className="form-control colorInput" value={hexColor} onChange={(e) => setHexColor(e.target.value)} />
                            <input type="color" value={hexColor} onChange={handleInputChange} className='colorBox' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Typography</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p>Header</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <div className='memberInfos w-100'>
                                <div className='dropdown w-100'>
                                    <div onClick={(e) => { setIsActive(!isActive); }} className='dropdown-btn w-100'>
                                        <div className='tableImage w-100'>
                                            {selectFont && selectFont.length ? <div className='d-flex justify-content-between w-100'>
                                                <p>{selectFont.length > 28 ? selectFont.substring(0, 28) + "..." : selectFont}</p>
                                                <p><FontAwesomeIcon icon={faChevronDown} /></p>
                                            </div> : <div className='d-flex justify-content-between w-100'><p>Select Fonts</p><p><FontAwesomeIcon icon={faChevronDown} /></p></div>}
                                        </div>

                                    </div>
                                    <div className='dropdown-content' style={{ display: isActive ? "block" : "none" }} >
                                        <div className='assignInput px-2'>
                                            <FontAwesomeIcon icon={faSearch} />
                                            <input type='text' placeholder='Search font' onChange={handleSearch} className='form-control' />
                                        </div>
                                        <div className='member-container'>
                                            {limitedFonts.map((fonts: any, index: number) => (
                                                <div
                                                    key={`assignSpace` + index}
                                                    onClick={(e) => {
                                                        setIsActive(!isActive);
                                                        setSelectFont(fonts);
                                                    }}
                                                    className='item tableImage'
                                                >
                                                    <p>{fonts.length > 17 ? fonts.slice(0, 17) + "..." : fonts}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Subheader</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <div className='memberInfos w-100'>
                                <div className='dropdown w-100'>
                                    <div onClick={(e) => { setIsDropActive(!isDropActive); }} className='dropdown-btn w-100'>
                                        <div className='tableImage w-100'>
                                            {selectSubFont && selectSubFont.length ? <div className='d-flex justify-content-between w-100'>
                                                <p>{selectSubFont.length > 28 ? selectSubFont.substring(0, 28) + "..." : selectSubFont}</p>
                                                <p><FontAwesomeIcon icon={faChevronDown} /></p>
                                            </div> : <div className='d-flex justify-content-between w-100'><p>Select Fonts</p><p><FontAwesomeIcon icon={faChevronDown} /></p></div>}
                                        </div>

                                    </div>
                                    <div className='dropdown-content' style={{ display: isDropActive ? "block" : "none" }} >
                                        <div className='assignInput px-2'>
                                            <FontAwesomeIcon icon={faSearch} />
                                            <input type='text' placeholder='Search font' onChange={handleSubSearch} className='form-control' />
                                        </div>
                                        <div className='member-container'>
                                            {limitedSubFonts.map((fonts: any, index: number) => (
                                                <div
                                                    key={`assignSpace` + index}
                                                    onClick={(e) => {
                                                        setIsDropActive(!isDropActive);
                                                        setSelectSubFont(fonts);
                                                    }}
                                                    className='item tableImage'
                                                >
                                                    <p>{fonts.length > 17 ? fonts.slice(0, 17) + "..." : fonts}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Global